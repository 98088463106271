import React from 'react'

function Footer() {
    return (
        <div className="footer-container">
            <p>* View on PC to get full features</p>
        </div>
    )
}

export default Footer
